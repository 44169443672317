import React from "react"
import { graphql,Link } from "gatsby"
import Containers from "../components/container"
import Header from "../components/header"
import Headertop from "../components/headertop"
import Footer from "../components/footer"
import Formfooter from "../components/form-footer"
import { Container, Col, Row, Tabs, Tab } from "react-bootstrap"
import * as singleproductStyles from "../css/singleproduct.module.css"
import { Icon } from '@iconify/react';
import arrowRight from '@iconify/icons-akar-icons/arrow-right';
import chevronDoubleRight from '@iconify/icons-mdi-light/chevron-double-right';
import Img from "gatsby-image"
import Bgproduct from "../components/bgproduct"
import ProductRelated from './productRelated'
import lineIcon from '@iconify/icons-bi/line';
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import Seo from "../components/seo"
import ProductCatalogs from "./productCatalogs"
import styled from "styled-components"

const BottomWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
  margin-top: 30px;
  .salebuttom {
    background: #00c300;
    border-color: #00c300;
    border-radius: 30px;
    padding: 10px 25px;
    color: #fff;
    :hover {
      background: #01b101;
      border-color: #01b101;
      color: #fff;
    }
  }
  .saledistributor {
    background-color: #050248;
    border-color: #050248;
    border-radius: 30px;
    padding: 10px 20px;
    color: #fff;
    margin-left: 10px;
    :hover {
      background: #007bff;
      border-color: #007bff;
      color: #fff;
    }
  }
  @media only screen and (min-width: 1024px) {
    .salebuttom {
      padding: 10px 35px;
    }
    .saledistributor {
      padding: 10px 35px;
    }
  }
`

export default function Product({ data }) {
  const post = data.markdownRemark
  const linkcat = post.frontmatter.categories
  const url = post.fields.slug
  const firstlink = "/"
  const secondlink = "/"
  const link = firstlink + linkcat + secondlink
  const line = "https://lin.ee/7y11wvo";
  return (
    <Containers>
          <Seo 
           title={post.frontmatter.title}
           description={post.frontmatter.description}
           image={post.frontmatter.featuredImage.childImageSharp.fluid}
           pathname={url}
           />
      <Headertop />
      <Header />
      <Bgproduct />
      <div className={singleproductStyles.herob}>
      <Container fluid="xl">
              <Row>
                  <Col><p><Link to="/">Home</Link> <Icon icon={chevronDoubleRight} /> <Link to="/categories/">Product</Link> <Icon icon={chevronDoubleRight} /> <Link to={link}>{post.frontmatter.namecat}</Link> <Icon icon={chevronDoubleRight} /> {post.frontmatter.title}</p></Col>
              </Row>
      </Container>
      </div>
      <div className={singleproductStyles.herosection}>
        <Container fluid="xl">
          <Row>
            <Col md={6}>
            <SimpleReactLightbox>
              <SRLWrapper>
              <a href={post.frontmatter.featuredImageset1.childImageSharp.fluid.src}>
              <Img fluid={post.frontmatter.featuredImageset1.childImageSharp.fluid} className={singleproductStyles.imgproduct} />
              </a>
                 <Containers>
                        <Row>
                          <Col xs={4} md={4}>
                            <a href={post.frontmatter.Imageaset1.childImageSharp.fluid.src}>
                            <Img fluid={post.frontmatter.Imageaset1.childImageSharp.fluid} className={singleproductStyles.imgproduct} />
                            </a>
                          </Col>
                          <Col xs={4} md={4}>
                            <a href={post.frontmatter.Imagebset1.childImageSharp.fluid.src}>
                            <Img fluid={post.frontmatter.Imagebset1.childImageSharp.fluid} className={singleproductStyles.imgproduct} />
                            </a>
                          </Col>
                          <Col xs={4} md={4}>
                            <a href={post.frontmatter.Imagecset1.childImageSharp.fluid.src}>
                            <Img fluid={post.frontmatter.Imagecset1.childImageSharp.fluid} className={singleproductStyles.imgproduct} />
                            </a>
                          </Col>
                        </Row>
                 </Containers>
                 </SRLWrapper>
            </SimpleReactLightbox>
            </Col>
            <Col md={6}>
            <h1>{post.frontmatter.title1}</h1>
            <p>{post.frontmatter.descriptionset1}</p>
            {linkcat === "garment" ? (
              <BottomWrapper>
                <div>
                  <a href={line} target="_blank" rel="noreply noreferrer" className="salebuttom"><Icon icon={lineIcon} /> สั่งซื้อ <Icon icon={arrowRight} /></a>
                </div>
                <div>
                  <a href="/distributor/" rel="noreferrer" className="saledistributor">ตัวแทนจำหน่าย <Icon icon={arrowRight} /></a>
                </div>
              </BottomWrapper>
            ): (
              <BottomWrapper>
                <a href={line} target="_blank" rel="noreply noreferrer" className="salebuttom"><Icon icon={lineIcon} /> สั่งซื้อ <Icon icon={arrowRight} /></a>
              </BottomWrapper>
            )}
            <div className={singleproductStyles.tablecat}>
            <Containers>
              <Row>
                <Col xs={4} md={4}><b>เบอร์</b></Col>
                <Col xs={8} md={8}>{post.frontmatter.sizeset1}</Col>
              </Row>
              <Row>
                <Col xs={12} md={12}><hr className={singleproductStyles.hr} /></Col>
              </Row>
              <Row>
                <Col xs={4} md={4}><b>ขนาด</b></Col>
                <Col xs={8} md={8}>{post.frontmatter.color1}</Col>
              </Row>
            </Containers>
            </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className={singleproductStyles.herosection}>
        <Container fluid="xl">
          <Row>
            <Col md={6}>
            <SimpleReactLightbox>
              <SRLWrapper>
              <a href={post.frontmatter.featuredImageset2.childImageSharp.fluid.src}> 
              <Img fluid={post.frontmatter.featuredImageset2.childImageSharp.fluid} className={singleproductStyles.imgproduct} />
              </a>
                 <Containers>
                        <Row>
                          <Col xs={4} md={4}>
                            <a href={post.frontmatter.Imageaset2.childImageSharp.fluid.src}>
                            <Img fluid={post.frontmatter.Imageaset2.childImageSharp.fluid} className={singleproductStyles.imgproduct} />
                            </a>
                          </Col>
                          <Col xs={4} md={4}>
                            <a href={post.frontmatter.Imagebset2.childImageSharp.fluid.src}>
                            <Img fluid={post.frontmatter.Imagebset2.childImageSharp.fluid} className={singleproductStyles.imgproduct} />
                            </a>
                          </Col>
                          <Col xs={4} md={4}>
                            <a href={post.frontmatter.Imagecset2.childImageSharp.fluid.src}>
                            <Img fluid={post.frontmatter.Imagecset2.childImageSharp.fluid} className={singleproductStyles.imgproduct} />
                            </a>
                          </Col>
                        </Row>
                 </Containers>
                 </SRLWrapper>
            </SimpleReactLightbox>
            </Col>
            <Col md={6}>
            <h1>{post.frontmatter.title2}</h1>
            <p>{post.frontmatter.descriptionset2}</p>
            {linkcat === "garment" ? (
              <BottomWrapper>
                <div>
                  <a href={line} target="_blank" rel="noreply noreferrer" className="salebuttom"><Icon icon={lineIcon} /> สั่งซื้อ <Icon icon={arrowRight} /></a>
                </div>
                <div>
                  <a href="/distributor/" rel="noreferrer" className="saledistributor">ตัวแทนจำหน่าย <Icon icon={arrowRight} /></a>
                </div>
              </BottomWrapper>
            ): (
              <BottomWrapper>
                <a href={line} target="_blank" rel="noreply noreferrer" className="salebuttom"><Icon icon={lineIcon} /> สั่งซื้อ <Icon icon={arrowRight} /></a>
              </BottomWrapper>
            )}
            <div className={singleproductStyles.tablecat}>
            <Containers>
              <Row>
                <Col xs={4} md={4}><b>เบอร์</b></Col>
                <Col xs={8} md={8}>{post.frontmatter.sizeset2}</Col>
              </Row>
              <Row>
                <Col xs={12} md={12}><hr className={singleproductStyles.hr} /></Col>
              </Row>
              <Row>
                <Col xs={4} md={4}><b>ขนาด</b></Col>
                <Col xs={8} md={8}>{post.frontmatter.color2}</Col>
              </Row>
            </Containers>
            </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className={singleproductStyles.herosection}>
        <Container fluid="xl">
          <Row>
            <Col>
            <Tabs defaultActiveKey="description" id="uncontrolled-tab-example" className="mb-2">
              <Tab eventKey="description" title="รายละเอียดสินค้า">
                <div className={singleproductStyles.herodes}>
                  <Containers>
                    <Row>
                      <Col md={12}>
                        <div dangerouslySetInnerHTML={{ __html: post.html }} />
                      </Col>
                    </Row>
                  </Containers>
                </div>
              </Tab>
              <Tab eventKey="Color-Product" title="แคตตาล็อกสี">
                <div className={singleproductStyles.herodes}>
                  <Containers>
                    <Row>
                      <Col md={12}>
                        <ProductCatalogs slug={post.fields.slug} title={post.frontmatter.title} />
                      </Col>
                    </Row>
                  </Containers>
                </div>
              </Tab>
            </Tabs>
            </Col>
          </Row>
        </Container>
      </div>
      <div className={singleproductStyles.herosection}>
        <Container fluid="xl">
          <Row>
            <Col md={12}>
                <hr style={{ marginBottom:"30px" }} />
                <h3 style={{ fontSize:"28px" }}>สินค้าที่เกี่ยวข้อง</h3>
                <ProductRelated slug={post.fields.slug} cat={post.frontmatter.categories} />
            </Col>
          </Row>
        </Container>
      </div>
      <Formfooter />
      <Footer />
    </Containers>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      fields {
        slug
      }
      frontmatter {
        namecat
        title
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 1200) {
              src
              ...GatsbyImageSharpFluid
            }
          }
        }
        title1
        title2
        descriptionset1
        descriptionset2
        categories
        sizeset1
        sizeset2
        color1
        color2
        featuredImageset1 {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  src
                  ...GatsbyImageSharpFluid
            }
          }
        }
        featuredImageset2 {
            childImageSharp {
              fluid(maxWidth: 1200) {
                src
                ...GatsbyImageSharpFluid
            }
          }
        }
        Imageaset1 {
            childImageSharp {
              fluid(maxWidth: 1200) {
                src
                ...GatsbyImageSharpFluid
            }
          }
        }
        Imagebset1 {
            childImageSharp {
              fluid(maxWidth: 1200) {
                src
                ...GatsbyImageSharpFluid
            }
          }
        }
        Imagecset1 {
            childImageSharp {
              fluid(maxWidth: 1200) {
                src
                ...GatsbyImageSharpFluid
            }
          }
        }
        Imageaset2 {
            childImageSharp {
              fluid(maxWidth: 1200) {
                src
                ...GatsbyImageSharpFluid
            }
          }
        }
        Imagebset2 {
            childImageSharp {
              fluid(maxWidth: 1200) {
                src
                ...GatsbyImageSharpFluid
            }
          }
        }
        Imagecset2 {
            childImageSharp {
              fluid(maxWidth: 1200) {
                src
                ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`